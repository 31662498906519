<template>
  <!-- 查看问卷调查 -->
  <div class="view_FinishPaper">
    <CommonFinishPaper ref="commonFinishPaper"
                       :autoid="mautoid"
                       :username="username"></CommonFinishPaper>
  </div>
</template>

<script>
import CommonFinishPaper from './commonFinishPaper'
// exitPrompt
export default {
  components: {
    CommonFinishPaper
  },
  data () {
    let mautoid = this.$route.query.mautoid
    let username = this.$route.query.username
    return {
      mautoid,
      username
    }
  }

};
</script>
 
 
<style lang="less" scoped>
.view_FinishPaper {
  width: 100%;
  height: 100%;
}
</style>